@font-face {
    font-family: 'HelveticaNowText';
    src: url('HelveticaNowText-Medium.woff2') format('woff2'),
        url('HelveticaNowText-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HelveticaNowText';
    src: url('HelveticaNowText-Bold.woff2') format('woff2'),
        url('HelveticaNowText-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HelveticaNowText';
    src: url('HelveticaNowText-Regular.woff2') format('woff2'),
        url('HelveticaNowText-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HelveticaNowDisplay';
    src: url('HelveticaNowDisplay-Md.woff2') format('woff2'),
        url('HelveticaNowDisplay-Md.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HelveticaNowDisplay';
    src: url('HelveticaNowDisplay-XBd.woff2') format('woff2'),
        url('HelveticaNowDisplay-XBd.woff') format('woff');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

